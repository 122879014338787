import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/container'
import Article from '../components/article'

const ArticlePage = ({ data, location }) => {
  const articles = data.allContentfulAktuelles.nodes

  const article = articles
    .map((a) => ({
      ...a,
      title: a.title,
      slug: a.slug,
      author: a.author,
      publishedAt: a.publishedAt,
      image: a.image,
      content: a.content,
      node_locale: a.node_locale,
    }))
    .filter((a) => a.node_locale === 'en')[0]

  const translatedSlug = articles
    .map((a) => ({
      ...a,
      slug: a.slug,
      node_locale: a.node_locale,
    }))
    .filter((a) => a.node_locale === 'de')[0].slug

  const additionalHeadTag = [
    <meta property="og:image" content={`${article.imageUrl.file.url}?w=1200&h=630&fit=fill&f=faces`} />,
    <meta property="og:url" content={`${data.site.siteMetadata.siteUrl}/en/news/${article.slug}/`} />,
    article.company.map((company) => {
      return company.name === 'ES-Tec GmbH' && <link rel="canonical" hrefLang="en" href={`${company.website}/en/news/${article.slug}/`} key={company.website} />
    })
  ]

  const PageHeader = <Header title={article.title} />

  return (
    <Layout
      translatedPageLinks={[
        { code: 'en', link: '/en/news/' + article.slug + '/' },
        { code: 'de', link: '/de/aktuelles/' + translatedSlug + '/' },
      ]}
      pageLang="en"
      pageTitle={article.title}
      pageDescription={article.excerpt.excerpt}
      pageHeader={PageHeader}
      additionalHeadTag={additionalHeadTag}
      location={location}
    >
      <Container>
        <section>
          <Article
            title={article.title}
            authorName={article.author.name}
            publishedAt={article.publishedAt}
            image={article.image.gatsbyImageData}
            imageDescription={article.image.description}
            content={article.content}
            downloads={article.downloads}
            url={`${data.site.siteMetadata.siteUrl}/en/news/${article.slug}/`}
          />
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allContentfulAktuelles(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        title
        slug
        author {
          name
        }
        publishedAt
        image {
          description
          gatsbyImageData(
            width: 576
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 90
          )
        }
        imageUrl: image {
          file {
            url
          }
        }
        excerpt {
          excerpt
        }
        company {
          name
          website
        }
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              description
              gatsbyImageData(
                width: 1050
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
              width
              height
              __typename
            }
          }
        }
        downloads {
          title
          mimeType
          size
          url
        }
        node_locale
      }
    }
  }
`

export default ArticlePage
